<template lang='pug'>
  .newsletter
    .splash
      h1.animated.fadeInUp Newsletter
      h3.animated.fadeInUp.wait-p2s Get notified of important announcements and new major releases.
    .newsletter-content
      v-container.pa-0(fluid, grid-list-xl)
        v-layout(row)
          v-flex(xs1, xl2).grey.lighten-4.hidden-sm-and-down
          v-flex(xs12, md10, lg6, xl4).grey.lighten-4
            .newsletter-form
              h2 Subscribe
              .newsletter-form-text You can opt-out at any time. Your info will never be shared to a 3rd-party.
              .newsletter-form-text We hate spam just as much as you do!
              .newsletter-form-fields
                v-text-field(
                  v-model='email'
                  label='Email Address'
                  )
                v-text-field(
                  v-model='fname'
                  label='First Name'
                  )
                v-select(
                  v-model='country'
                  :items='countries'
                  label='Country'
                  append-icon=''
                  )
              v-alert(:color='alertColor', v-model='alertShown', outline) {{alertMessage}}
              v-btn(large, color='primary', block, @click='subscribe', :loading='isLoading') Subscribe
              .newsletter-form-link
                a(href='https://us20.campaign-archive.com/home/?u=2c5b24b2d2601202fdc7570cd&id=cecee43e80', target='_blank') View previous newsletters
          v-flex(xs12, lg6, xl6).white.hidden-md-and-down
            .newsletter-img
              img.animated.fadeInUp(:src='require("../assets/illustrations/fogg-message-sent.png")')
          v-flex(xs1, lg2).grey.lighten-4.hidden-sm-and-down.hidden-lg-and-up
</template>

<script>
import _ from 'lodash'

import gql from 'graphql-tag'

export default {
  data () {
    return {
      alertShown: false,
      alertColor: 'success',
      alertMessage: '',
      isLoading: false,
      email: '',
      fname: '',
      country: '',
      countries: [
        { value: 'Aaland Islands', text: 'Aaland Islands' },
        { value: 'Afghanistan', text: 'Afghanistan' },
        { value: 'Albania', text: 'Albania' },
        { value: 'Algeria', text: 'Algeria' },
        { value: 'American Samoa', text: 'American Samoa' },
        { value: 'Andorra', text: 'Andorra' },
        { value: 'Angola', text: 'Angola' },
        { value: 'Anguilla', text: 'Anguilla' },
        { value: 'Antarctica', text: 'Antarctica' },
        { value: 'Antigua And Barbuda', text: 'Antigua And Barbuda' },
        { value: 'Argentina', text: 'Argentina' },
        { value: 'Armenia', text: 'Armenia' },
        { value: 'Aruba', text: 'Aruba' },
        { value: 'Australia', text: 'Australia' },
        { value: 'Austria', text: 'Austria' },
        { value: 'Azerbaijan', text: 'Azerbaijan' },
        { value: 'Bahamas', text: 'Bahamas' },
        { value: 'Bahrain', text: 'Bahrain' },
        { value: 'Bangladesh', text: 'Bangladesh' },
        { value: 'Barbados', text: 'Barbados' },
        { value: 'Belarus', text: 'Belarus' },
        { value: 'Belgium', text: 'Belgium' },
        { value: 'Belize', text: 'Belize' },
        { value: 'Benin', text: 'Benin' },
        { value: 'Bermuda', text: 'Bermuda' },
        { value: 'Bhutan', text: 'Bhutan' },
        { value: 'Bolivia', text: 'Bolivia' },
        { value: 'Bonaire, Saint Eustatius and Saba', text: 'Bonaire, Saint Eustatius and Saba' },
        { value: 'Bosnia and Herzegovina', text: 'Bosnia and Herzegovina' },
        { value: 'Botswana', text: 'Botswana' },
        { value: 'Bouvet Island', text: 'Bouvet Island' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'British Indian Ocean Territory', text: 'British Indian Ocean Territory' },
        { value: 'Brunei Darussalam', text: 'Brunei Darussalam' },
        { value: 'Bulgaria', text: 'Bulgaria' },
        { value: 'Burkina Faso', text: 'Burkina Faso' },
        { value: 'Burundi', text: 'Burundi' },
        { value: 'Cambodia', text: 'Cambodia' },
        { value: 'Cameroon', text: 'Cameroon' },
        { value: 'Canada', text: 'Canada' },
        { value: 'Cape Verde', text: 'Cape Verde' },
        { value: 'Cayman Islands', text: 'Cayman Islands' },
        { value: 'Central African Republic', text: 'Central African Republic' },
        { value: 'Chad', text: 'Chad' },
        { value: 'Chile', text: 'Chile' },
        { value: 'China', text: 'China' },
        { value: 'Christmas Island', text: 'Christmas Island' },
        { value: 'Cocos (Keeling) Islands', text: 'Cocos (Keeling) Islands' },
        { value: 'Colombia', text: 'Colombia' },
        { value: 'Comoros', text: 'Comoros' },
        { value: 'Congo', text: 'Congo' },
        { value: 'Cook Islands', text: 'Cook Islands' },
        { value: 'Costa Rica', text: 'Costa Rica' },
        { value: 'Cote D\'Ivoire', text: 'Cote D\'Ivoire' },
        { value: 'Croatia', text: 'Croatia' },
        { value: 'Cuba', text: 'Cuba' },
        { value: 'Curacao', text: 'Curacao' },
        { value: 'Cyprus', text: 'Cyprus' },
        { value: 'Czech Republic', text: 'Czech Republic' },
        { value: 'Democratic Republic of the Congo', text: 'Democratic Republic of the Congo' },
        { value: 'Denmark', text: 'Denmark' },
        { value: 'Djibouti', text: 'Djibouti' },
        { value: 'Dominica', text: 'Dominica' },
        { value: 'Dominican Republic', text: 'Dominican Republic' },
        { value: 'Ecuador', text: 'Ecuador' },
        { value: 'Egypt', text: 'Egypt' },
        { value: 'El Salvador', text: 'El Salvador' },
        { value: 'Equatorial Guinea', text: 'Equatorial Guinea' },
        { value: 'Eritrea', text: 'Eritrea' },
        { value: 'Estonia', text: 'Estonia' },
        { value: 'Ethiopia', text: 'Ethiopia' },
        { value: 'Falkland Islands', text: 'Falkland Islands' },
        { value: 'Faroe Islands', text: 'Faroe Islands' },
        { value: 'Fiji', text: 'Fiji' },
        { value: 'Finland', text: 'Finland' },
        { value: 'France', text: 'France' },
        { value: 'French Guiana', text: 'French Guiana' },
        { value: 'French Polynesia', text: 'French Polynesia' },
        { value: 'French Southern Territories', text: 'French Southern Territories' },
        { value: 'Gabon', text: 'Gabon' },
        { value: 'Gambia', text: 'Gambia' },
        { value: 'Georgia', text: 'Georgia' },
        { value: 'Germany', text: 'Germany' },
        { value: 'Ghana', text: 'Ghana' },
        { value: 'Gibraltar', text: 'Gibraltar' },
        { value: 'Greece', text: 'Greece' },
        { value: 'Greenland', text: 'Greenland' },
        { value: 'Grenada', text: 'Grenada' },
        { value: 'Guadeloupe', text: 'Guadeloupe' },
        { value: 'Guam', text: 'Guam' },
        { value: 'Guatemala', text: 'Guatemala' },
        { value: 'Guernsey', text: 'Guernsey' },
        { value: 'Guinea', text: 'Guinea' },
        { value: 'Guinea-Bissau', text: 'Guinea-Bissau' },
        { value: 'Guyana', text: 'Guyana' },
        { value: 'Haiti', text: 'Haiti' },
        { value: 'Heard and Mc Donald Islands', text: 'Heard and Mc Donald Islands' },
        { value: 'Honduras', text: 'Honduras' },
        { value: 'Hong Kong', text: 'Hong Kong' },
        { value: 'Hungary', text: 'Hungary' },
        { value: 'Iceland', text: 'Iceland' },
        { value: 'India', text: 'India' },
        { value: 'Indonesia', text: 'Indonesia' },
        { value: 'Iran', text: 'Iran' },
        { value: 'Iraq', text: 'Iraq' },
        { value: 'Ireland', text: 'Ireland' },
        { value: 'Isle of Man', text: 'Isle of Man' },
        { value: 'Israel', text: 'Israel' },
        { value: 'Italy', text: 'Italy' },
        { value: 'Jamaica', text: 'Jamaica' },
        { value: 'Japan', text: 'Japan' },
        { value: 'Jersey  (Channel Islands)', text: 'Jersey  (Channel Islands)' },
        { value: 'Jordan', text: 'Jordan' },
        { value: 'Kazakhstan', text: 'Kazakhstan' },
        { value: 'Kenya', text: 'Kenya' },
        { value: 'Kiribati', text: 'Kiribati' },
        { value: 'Kuwait', text: 'Kuwait' },
        { value: 'Kyrgyzstan', text: 'Kyrgyzstan' },
        { value: 'Lao People\'s Democratic Republic', text: 'Lao People\'s Democratic Republic' },
        { value: 'Latvia', text: 'Latvia' },
        { value: 'Lebanon', text: 'Lebanon' },
        { value: 'Lesotho', text: 'Lesotho' },
        { value: 'Liberia', text: 'Liberia' },
        { value: 'Libya', text: 'Libya' },
        { value: 'Liechtenstein', text: 'Liechtenstein' },
        { value: 'Lithuania', text: 'Lithuania' },
        { value: 'Luxembourg', text: 'Luxembourg' },
        { value: 'Macau', text: 'Macau' },
        { value: 'Macedonia', text: 'Macedonia' },
        { value: 'Madagascar', text: 'Madagascar' },
        { value: 'Malawi', text: 'Malawi' },
        { value: 'Malaysia', text: 'Malaysia' },
        { value: 'Maldives', text: 'Maldives' },
        { value: 'Mali', text: 'Mali' },
        { value: 'Malta', text: 'Malta' },
        { value: 'Marshall Islands', text: 'Marshall Islands' },
        { value: 'Martinique', text: 'Martinique' },
        { value: 'Mauritania', text: 'Mauritania' },
        { value: 'Mauritius', text: 'Mauritius' },
        { value: 'Mayotte', text: 'Mayotte' },
        { value: 'Mexico', text: 'Mexico' },
        { value: 'Micronesia, Federated States of', text: 'Micronesia, Federated States of' },
        { value: 'Moldova, Republic of', text: 'Moldova, Republic of' },
        { value: 'Monaco', text: 'Monaco' },
        { value: 'Mongolia', text: 'Mongolia' },
        { value: 'Montenegro', text: 'Montenegro' },
        { value: 'Montserrat', text: 'Montserrat' },
        { value: 'Morocco', text: 'Morocco' },
        { value: 'Mozambique', text: 'Mozambique' },
        { value: 'Myanmar', text: 'Myanmar' },
        { value: 'Namibia', text: 'Namibia' },
        { value: 'Nauru', text: 'Nauru' },
        { value: 'Nepal', text: 'Nepal' },
        { value: 'Netherlands', text: 'Netherlands' },
        { value: 'Netherlands Antilles', text: 'Netherlands Antilles' },
        { value: 'New Caledonia', text: 'New Caledonia' },
        { value: 'New Zealand', text: 'New Zealand' },
        { value: 'Nicaragua', text: 'Nicaragua' },
        { value: 'Niger', text: 'Niger' },
        { value: 'Nigeria', text: 'Nigeria' },
        { value: 'Niue', text: 'Niue' },
        { value: 'Norfolk Island', text: 'Norfolk Island' },
        { value: 'North Korea', text: 'North Korea' },
        { value: 'Northern Mariana Islands', text: 'Northern Mariana Islands' },
        { value: 'Norway', text: 'Norway' },
        { value: 'Oman', text: 'Oman' },
        { value: 'Pakistan', text: 'Pakistan' },
        { value: 'Palau', text: 'Palau' },
        { value: 'Palestine', text: 'Palestine' },
        { value: 'Panama', text: 'Panama' },
        { value: 'Papua New Guinea', text: 'Papua New Guinea' },
        { value: 'Paraguay', text: 'Paraguay' },
        { value: 'Peru', text: 'Peru' },
        { value: 'Philippines', text: 'Philippines' },
        { value: 'Pitcairn', text: 'Pitcairn' },
        { value: 'Poland', text: 'Poland' },
        { value: 'Portugal', text: 'Portugal' },
        { value: 'Puerto Rico', text: 'Puerto Rico' },
        { value: 'Qatar', text: 'Qatar' },
        { value: 'Republic of Kosovo', text: 'Republic of Kosovo' },
        { value: 'Reunion', text: 'Reunion' },
        { value: 'Romania', text: 'Romania' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Rwanda', text: 'Rwanda' },
        { value: 'Saint Kitts and Nevis', text: 'Saint Kitts and Nevis' },
        { value: 'Saint Lucia', text: 'Saint Lucia' },
        { value: 'Saint Martin', text: 'Saint Martin' },
        { value: 'Saint Vincent and the Grenadines', text: 'Saint Vincent and the Grenadines' },
        { value: 'Samoa (Independent)', text: 'Samoa (Independent)' },
        { value: 'San Marino', text: 'San Marino' },
        { value: 'Sao Tome and Principe', text: 'Sao Tome and Principe' },
        { value: 'Saudi Arabia', text: 'Saudi Arabia' },
        { value: 'Senegal', text: 'Senegal' },
        { value: 'Serbia', text: 'Serbia' },
        { value: 'Seychelles', text: 'Seychelles' },
        { value: 'Sierra Leone', text: 'Sierra Leone' },
        { value: 'Singapore', text: 'Singapore' },
        { value: 'Sint Maarten', text: 'Sint Maarten' },
        { value: 'Slovakia', text: 'Slovakia' },
        { value: 'Slovenia', text: 'Slovenia' },
        { value: 'Solomon Islands', text: 'Solomon Islands' },
        { value: 'Somalia', text: 'Somalia' },
        { value: 'South Africa', text: 'South Africa' },
        { value: 'South Georgia and the South Sandwich Islands', text: 'South Georgia and the South Sandwich Islands' },
        { value: 'South Korea', text: 'South Korea' },
        { value: 'South Sudan', text: 'South Sudan' },
        { value: 'Spain', text: 'Spain' },
        { value: 'Sri Lanka', text: 'Sri Lanka' },
        { value: 'St. Helena', text: 'St. Helena' },
        { value: 'St. Pierre and Miquelon', text: 'St. Pierre and Miquelon' },
        { value: 'Sudan', text: 'Sudan' },
        { value: 'Suriname', text: 'Suriname' },
        { value: 'Svalbard and Jan Mayen Islands', text: 'Svalbard and Jan Mayen Islands' },
        { value: 'Swaziland', text: 'Swaziland' },
        { value: 'Sweden', text: 'Sweden' },
        { value: 'Switzerland', text: 'Switzerland' },
        { value: 'Syria', text: 'Syria' },
        { value: 'Taiwan', text: 'Taiwan' },
        { value: 'Tajikistan', text: 'Tajikistan' },
        { value: 'Tanzania', text: 'Tanzania' },
        { value: 'Thailand', text: 'Thailand' },
        { value: 'Timor-Leste', text: 'Timor-Leste' },
        { value: 'Togo', text: 'Togo' },
        { value: 'Tokelau', text: 'Tokelau' },
        { value: 'Tonga', text: 'Tonga' },
        { value: 'Trinidad and Tobago', text: 'Trinidad and Tobago' },
        { value: 'Tunisia', text: 'Tunisia' },
        { value: 'Turkey', text: 'Turkey' },
        { value: 'Turkmenistan', text: 'Turkmenistan' },
        { value: 'Turks &amp; Caicos Islands', text: 'Turks &amp; Caicos Islands' },
        { value: 'Turks and Caicos Islands', text: 'Turks and Caicos Islands' },
        { value: 'Tuvalu', text: 'Tuvalu' },
        { value: 'Uganda', text: 'Uganda' },
        { value: 'Ukraine', text: 'Ukraine' },
        { value: 'United Arab Emirates', text: 'United Arab Emirates' },
        { value: 'United Kingdom', text: 'United Kingdom' },
        { value: 'United States of America', text: 'United States of America' },
        { value: 'Uruguay', text: 'Uruguay' },
        { value: 'USA Minor Outlying Islands', text: 'USA Minor Outlying Islands' },
        { value: 'Uzbekistan', text: 'Uzbekistan' },
        { value: 'Vanuatu', text: 'Vanuatu' },
        { value: 'Vatican City State (Holy See)', text: 'Vatican City State (Holy See)' },
        { value: 'Venezuela', text: 'Venezuela' },
        { value: 'Vietnam', text: 'Vietnam' },
        { value: 'Virgin Islands (British)', text: 'Virgin Islands (British)' },
        { value: 'Virgin Islands (U.S.)', text: 'Virgin Islands (U.S.)' },
        { value: 'Wallis and Futuna Islands', text: 'Wallis and Futuna Islands' },
        { value: 'Western Sahara', text: 'Western Sahara' },
        { value: 'Yemen', text: 'Yemen' },
        { value: 'Zambia', text: 'Zambia' },
        { value: 'Zimbabwe', text: 'Zimbabwe' }
      ]
    }
  },
  methods: {
    async subscribe () {
      if (this.email.length < 7) {
        this.alertColor = 'error'
        this.alertMessage = 'Your email address is missing or invalid.'
        this.alertShown = true
      } else if (this.fname.length < 1) {
        this.alertColor = 'error'
        this.alertMessage = 'Your name is missing.'
        this.alertShown = true
      } else if (this.country.length < 2) {
        this.alertColor = 'error'
        this.alertMessage = 'Please select a country.'
        this.alertShown = true
      } else {
        this.alertShown = false
        this.isLoading = true
        try {
          const resp = await this.$apollo.mutate({
            mutation: gql`
              mutation($email: String!, $name: String!, $country: String!) {
                newsletter {
                  subscribe(email:$email, name:$name, country:$country) {
                    responseResult {
                      succeeded
                      errorCode
                      slug
                      message
                    }
                  }
                }
              }
            `,
            variables: {
              email: this.email,
              name: this.fname,
              country: this.country
            }
          })
          if (_.get(resp, 'data.newsletter.subscribe.responseResult.succeeded', false) !== true) {
            throw new Error(_.get(resp, 'data.newsletter.subscribe.responseResult.message', 'Something went wrong. Try again later.'))
          }
          this.alertColor = 'success'
          this.alertMessage = 'Success! Check your emails to confirm your subscription.'
          this.alertShown = true
        } catch (err) {
          this.alertColor = 'error'
          this.alertMessage = err.message
          this.alertShown = true
        }
        this.isLoading = false
      }
    }
  },
  mounted () {
    this.alertShown = false
  }
}
</script>

<style lang='scss'>
.newsletter {
  &-content {
    padding: 12px;
  }

  &-form {
    padding: 100px 25px;

    h2 {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    &-text {
      font-size: 15px;
      font-weight: 300;
      color: var(--v-greyish-darken1);
    }

    &-fields {
      padding: 30px 0;
    }

    &-link {
      text-align: center;
      margin-top: 30px;
      font-size: 15px;

      a {
        text-decoration: none;
      }
    }
  }
  &-img {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 450px;
      width: 100%;
    }
  }
}
</style>
